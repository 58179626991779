<template>
  <div class="product-stockslip">
    <KTCodePreview v-bind:title="'Danh sách sản phẩm nhập xuất kho'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="col-md-5">
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.start"
                  v-model="dpForm.startDate"
                ></date-picker>
                <span class="ml-1 mr-1"></span>
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.end"
                  v-model="dpForm.endDate"
                ></date-picker>
            </div>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filterdOptionsStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueType"
              :options="filterdOptionsType"
              :placeholder="'kiểu XNK'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedProductType"
              :options="listProductType"
              value-field="id"
              text-field="name"
            >
            </b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
              v-on:change="onChangeCompany"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Doanh nghiệp --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchCode"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProduct"
              :suggestions="filteredOptionsProduct"
              placeholder="mã, tên, mã vạch sản phẩm"
              :limit="10"
              @select="onSelectedProduct"
              @change="onInputChangeProduct"
              suggestionName="productName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProvider"
              :suggestions="filteredOptionProvider"
              placeholder="NCC"
              :limit="10"
              @select="onSelectedProvider"
              @change="onInputChangeProvider"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="selectedCateName"
              :suggestions="filteredCateOptions"
              placeholder="danh mục"
              :limit="200"
              @select="onSelectedCate"
              @change="onInputCateChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="selectedCate2Name"
              :suggestions="filteredCate2Options"
              placeholder="danh mục nội bộ"
              :limit="200"
              @select="onSelectedCate2"
              @change="onInputCate2Change"
              suggestionName="suggestionName"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchIMEI"
              type="text"
              placeholder="Tìm kiếm theo mã IMEI"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchDesciption"
              type="text"
              placeholder="Tìm kiếm theo nội dung"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="items"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(code)="row">
            <div class="productCode" style="cursor: pointer">
              <a
                class="font-weight-bold"
                style="color: black"
                v-bind:href="getLinkDoc(row.item)"
                target="_blank"
              >
                {{ row.item.code }}
              </a>
            </div>
            <div v-if="row.item.idNhanh" class="text-primary">
              <span>ID Nhanh:</span>
              <span style="font-size: 11px">( {{ row.item.idNhanh }} )</span>
            </div>
            <p class="text-primary">{{ row.item.createdAt }}</p>
            <p class="text-left">{{ row.item.createBy }}</p>
          </template>

          <template v-slot:cell(relateStockSlipId)="row">
            <div
              class="productCode"
              @click="linkToBill(row.item)"
              style="cursor: pointer"
            >
              <span v-text="row.item.relateStockCode"></span>
            </div>
          </template>
          <template v-slot:cell(sourceWarehouseId)="row">
            <span
              v-text="row.item.sourceWarehouseId"
              class="font-weight-bolder"
            ></span>
            <br />
            <span v-if="row.item.companyName"
              >( {{ row.item.companyName }} )</span
            >
          </template>
          <template v-slot:cell(unitPrice)="row">
            <div style="text-align: end">
              <span v-text="convertPrice(row.item.unitPrice)"></span>
            </div>
          </template>
          <template v-slot:cell(productName)="row">
            <p
              v-if="row.item.productCode"
              style="font-size: 12px; font-weight: 500; margin-bottom: 0"
            >
              ( {{ row.item.productCode }} )
            </p>
            <span
              v-text="row.item.productName"
              style="white-space: normal"
            ></span>
            <p class="text-imei-no" v-if="row.item.imeiCode.trim() !== ''">
              IMEI: {{ row.item.imeiCode }}
            </p>
          </template>
          <template v-slot:cell(quantity)="row">
            <div style="text-align: right">
              <span v-text="row.item.quantity" class="text-right"></span>
            </div>
          </template>
          <template v-slot:cell(providerName)="row">
            <p class="text-left text-primary">
              {{ row.item.providerName }}
            </p>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng :
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { IMPORT_STOCK, TIME_TRIGGER } from '@/utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import fileDownload from '@/utils/file-download';
import decounce from 'debounce';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import { xoa_dau, removeAccents, convertPrice } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { cloneDeep, map, find, assign } from 'lodash';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      format: 'dd-MM-yyyy',
      language: vi,
      items: [],
      searchDesciption: '',
      searchStock: '',
      searchCode: '',
      totalPages: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: IMPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'relateStockSlipId',
          label: 'Từ hóa đơn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '13%' },
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'unitPrice',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
        },
        {
          key: 'providerName',
          label: 'Nhà cung cấp',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '13%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      optionsStore: [
        {
          data: [],
        },
      ],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      selectedType: null,
      listType: [
        {
          id: 0,
          name: 'Tất cả',
        },
        {
          id: 1,
          name: 'Nhập NCC',
        },
        {
          id: 2,
          name: 'Nhập chuyển kho',
        },
        {
          id: 3,
          name: 'Nhập trả hàng',
        },
        {
          id: 4,
          name: 'Nhập chuyển hoàn',
        },
        {
          id: 5,
          name: 'Nhập thu cũ',
        },
        {
          id: 6,
          name: 'Nhập bảo hành',
        },
        {
          id: 7,
          name: 'Xuất trả NCC',
        },
        {
          id: 8,
          name: 'Xuất bán lẻ',
        },
        {
          id: 9,
          name: 'Xuất bán buôn',
        },
        {
          id: 10,
          name: 'Xuất giao hàng',
        },

        {
          id: 11,
          name: 'Xuất bảo hành',
        },
      ],
      cate2Options: [
        {
          data: [],
        },
      ],
      filteredCate2Options: [],
      selectedCate2Id: null,
      selectedCate2Name: '',
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
      valueType: [],
      optionsType: [
        {
          code: 1,
          name: 'Nhập NCC',
          shortName: 'Nhập NCC',
          checked: false,
        },
        {
          code: 2,
          name: 'Nhập chuyển kho',
          shortName: 'Nhập chuyển kho',
          checked: false,
        },
        {
          code: 3,
          name: 'Nhập trả hàng',
          shortName: 'Nhập trả hàng',
          checked: false,
        },
        {
          code: 4,
          name: 'Nhập chuyển hoàn',
          shortName: 'Nhập chuyển hoàn',
          checked: false,
        },
        {
          code: 5,
          name: 'Nhập thu cũ',
          shortName: 'Nhập thu cũ',
          checked: false,
        },
        {
          code: 6,
          name: 'Nhập bảo hành',
          shortName: 'Nhập bảo hành',
          checked: false,
        },
        {
          code: 7,
          name: 'Xuất trả NCC',
          shortName: 'Xuất trả NCC',
          checked: false,
        },
        {
          code: 8,
          name: 'Xuất bán lẻ',
          shortName: 'Xuất bán lẻ',
          checked: false,
        },
        {
          code: 9,
          name: 'Xuất bán buôn',
          shortName: 'Xuất bán buôn',
          checked: false,
        },
        {
          code: 10,
          name: 'Xuất giao hàng',
          shortName: 'Xuất giao hàng',
          checked: false,
        },
        {
          code: 11,
          name: 'Xuất bảo hành',
          shortName: 'Xuất bảo hành',
          checked: false,
        },
        {
          code: 12,
          name: 'Xuất chuyển kho',
          shortName: 'Xuất chuyển kho',
          checked: false,
        },
      ],
      selectedProductType: null,
      listProductType: [
        {
          id: null,
          name: 'Chọn loại SP',
        },
        {
          id: 1,
          name: 'Sản phẩm',
        },
        {
          id: 2,
          name: 'Sản phẩm theo IMEI',
        },
      ],
      filteredOptionProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      searchIMEI: '',
      filterdOptionsStore: [],
      filterdOptionsType: []
    };
  },
  methods: {
    convertPrice,
    fetchStore: async function () {
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`
      ).then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
        });
        this.filterdOptionsStore = cloneDeep(this.optionsStore);
      });
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              const index = this.optionsStore.findIndex(
                (item) => item.code == element.id
              );
              if (index > -1) {
                this.optionsStore[index].checked = true;
                this.valueStore.push(this.optionsStore[index]);
              }
            }
          });
          this.filterdOptionsStore = cloneDeep(this.optionsStore);
        }
        this.fetchData();
      });
    },
    reportClick: async function () {
      let listId = [];
      if (this.valueStore.length) {
        listId = this.valueStore.map(({ code }) => code);
      }
      let listType = [];
      if (this.valueType.length) {
        listType = this.valueType.map(({ code }) => code);
      }
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      if (this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }
      const searchDesciption = this.searchDesciption.trim().length
        ? this.search.trim()
        : null;
      const searchCode = this.searchId;
      const searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      const searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      const searchProduct = this.searchProduct.trim().length
        ? this.searchProduct.trim()
        : null;
      const companyId = this.selectedCompany;
      const searchImeiCode = this.searchIMEI.trim().length
        ? this.searchIMEI.trim()
        : null;
      const baseUrl = BASE_URL;

      const url = `${baseUrl}stocks/export-excel-detail`;
      await axios({
        url: url,
        method: 'GET',
        params: {
          searchProduct: searchProduct,
          searchDesciption: searchDesciption,
          searchCode: searchCode,
          searchFromDay: searchFromDay,
          searchToDay: searchToDay,
          companyId: companyId,
          cateId: this.selectedCateId,
          providerId: this.selectedProvider,
          searchIMEI: searchImeiCode,
          productType: this.selectedProductType,
          typeSearch: listType,
          internalCateId: this.selectedCate2Id,
          listStoreId: listId,
        },
        responseType: 'blob',
        onDownloadProgress: function (progressEvent) {
          console.log(progressEvent);
        },
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      };
      let listType = [];
      if (this.valueType.length) {
        listType = this.valueType.map(({ code }) => code);
      }
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      if (this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }

      const params = {
        page: this.page,
        limit: 10,
        searchDesciption: this.searchDesciption,
        searchCode: this.searchCode,
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59'
            )
          : '',
        searchProduct: this.searchProduct,
        listStoreId: listStoreId,
        companyId: this.selectedCompany,
        typeSearch: listType,
        cateId: this.selectedCateId,
        internalCateId: this.selectedCate2Id,
        productType: this.selectedProductType,
        providerId: this.selectedProvider,
        searchIMEI: this.searchIMEI.trim(),
      };
      ApiService.query('stock-slips/product-stock-slips', { params })
        .then((response) => {
          const stockResponse = response.data.data.data;
          this.totalPages = response.data.data.totalPages;
          this.totalItems = response.data.data.totalItems;
          this.items = stockResponse.map((item, index) => {
            return {
              count:
                this.page >= 2 ? index + 1 + this.page * 10 - 10 : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              destinationWarehouseId: item.destinationWarehouseId,
              providerId: item.providerId,
              providerName: item.providerName,
              description: item.description,
              status: this.status,
              type: item.type,
              createdAt: item.createdAt,
              createBy: item.createBy,
              relateStockSlipId: item.relateStockSlipId,
              stockSlipType: item.stockSlipType,
              transferStockSlipId: item.transferStockSlipId,
              idNhanh: item.idNhanh,
              companyName: item.companyName,
              productName: item.productName,
              productCode: item.productCode,
              quantity: item.quantity,
              imeiCode: item.imeiCode,
              unitPrice: item.unitPrice,
              relateStockCode: item.relateStockCode,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      if (item.type === 1) {
        this.$router.push({
          name: 'update-stock',
          query: { id: item.id },
        });
      } else if (item.type === 2) {
        this.$router.push({
          name: 'update-export-stock',
          query: { id: item.id },
        });
      }
    },
    onSelected(option) {
      this.selected = option.item.name;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    linkToBill: function (item) {
      let nameRouter = '';
      let query = { id: item.relateStockSlipId };
      switch (item.stockSlipType) {
        case 1:
          nameRouter = 'update-stock';
          break;
        case 2:
          nameRouter = 'update-export-stock';
          break;
        case 3:
          nameRouter = 'update-transfer-stock';
          break;
        case 5:
          nameRouter = 'update-transfer-stock';
          break;
        case 6:
          nameRouter = 'update-bill';
          break;
        case 7:
          nameRouter = 'update-wholesale-invoice';
          break;
        case 8:
          nameRouter = 'update-return-bill';
          break;
        case 9:
          nameRouter = 'update-order';
          break;
        case 10:
          nameRouter = 'update-bill';
          break;
        case 11:
          nameRouter = 'update-import-trade-in';
          break;
        case 12:
          nameRouter = 'update-transfer-stock-to-nhanh';
          break;
        case 13:
          nameRouter = 'update-transfer-stock-to-nhanh';
          break;
        case 14:
          nameRouter = 'update-transfer-stock-to-nhanh';
          break;
        case 15:
          nameRouter = 'update-import-warranty';
          break;
        case 16:
          nameRouter = 'update-export-warranty';
          break;
      }
      this.$router.push({
        name: nameRouter,
        query: query,
      });
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.get(
        `productSearch/withMerchant?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, TIME_TRIGGER),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-product-stockslip',
      });
      this.fetchData();
    },
    onSelect(option, type) {
      switch (type) {
        case 'Store': {
          let index = this.optionsStore.findIndex((item) => item.name == option.name);
          this.optionsStore[index].checked = true;
          this.filterdOptionsStore = cloneDeep(this.optionsStore);
          break;
        }
        case 'Type': {
          let index = this.optionsType.findIndex((item) => item.name == option.name);
          this.optionsType[index].checked = true;
          this.filterdOptionsType = cloneDeep(this.optionsType);
          break;
        }
        default:
          break;
      }
    },

    onRemove(option, type) {
      switch (type) {
        case 'Store': {
          let index = this.optionsStore.findIndex((item) => item.name == option.name);
          this.optionsStore[index].checked = false;
          this.filterdOptionsStore = cloneDeep(this.optionsStore);
          break;
        }
        case 'Type': {
          let index = this.optionsType.findIndex((item) => item.name == option.name);
          this.optionsType[index].checked = false;
          this.filterdOptionsType = cloneDeep(this.optionsType);
          break;
        }
        default:
          break;
      }
    },
    onInputChangeOptions(textInput = '', type) {
      switch (type) {
        case 'Store':
          this.searchStore(textInput);
          break;
        case 'Type':
          this.searchType(textInput);
          break;
        default:
          break;
      }
    },
    searchType(textInput) {
     let options = cloneDeep(this.optionsType);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsType = map(options, obj => {
          return assign(obj, find(this.filterdOptionsType, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex(prop => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, obj => {
        return assign(obj, find(this.filterdOptionsType, { id: obj.id }));
      });

      this.filterdOptionsType = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    searchStore(textInput) {
     let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsStore = map(options, obj => {
          return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex(prop => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, obj => {
        return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
      });

      this.filterdOptionsStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter(item => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    getLinkDoc(item) {
      if (item.type === 1) {
        return `#/stocks/update-stock?id=${item.id}`;
      } else if (item.type === 2) {
        return `#/export-stocks/update-export-stock?id=${item.id}`;
      }
    },
    fetchCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '- ' + element.name;
          } else if (element.level === 2) {
            name = '- - ' + element.name;
          } else if (element.level === 3) {
            name = '- - - ' + element.name;
          } else if (element.level === 4) {
            name = '- - - - ' + element.name;
          } else if (element.level === 5) {
            name = '- - - - - ' + element.name;
          } else if (element.level === 6) {
            name = '- - - - - - ' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions[0].data];
      });
    },
    fetchInternalCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category/internal-cate', paramQuery).then(
        ({ data }) => {
          this.cate2Options[0].data = data.data.map((element) => {
            return {
              id: element.id,
              suggestionName: element.name,
              name: element.name,
            };
          });
          this.filteredCate2Options = [...this.cate2Options[0].data];
        }
      );
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [...filteredData];
    },
    onSelectedCate2(option) {
      this.selectedCate2Id = option.item.id;
      this.selectedCate2Name = option.item.name;
    },
    onInputCate2Change(text) {
      this.selectedCate2Name = text;

      const filteredData = this.cate2Options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCate2Options = [...filteredData];
    },

    fetchProvider() {
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProvider = data.data.providers;
        const noneProvider = {
          id: null,
          name: 'Tất cả',
        };
        this.listProvider.unshift(noneProvider);
        this.listProvider.map((element) => {
          this.optionsProvider[0].data.push(element);
        });
        this.filteredOptionProvider = [...this.optionsProvider[0].data];
      });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.selectedProvider = option.item.id;
    },
    onInputChangeProvider(text) {
      if (!text) {
        text = '';
      }
      this.searchProvider = text;
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          const itemName = xoa_dau(item.name);
          return itemName.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionProvider = [...filteredData];
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm nhập xuất kho', route: 'stocks' },
      { title: 'Danh sách sản phẩm nhập xuất kho' },
    ]);
  },
  created() {
    this.fetchStore();
    this.fetchCompany();
    this.fetchCategory();
    this.fetchInternalCategory();
    this.fetchProvider();
    this.fetchStoreByUser();
    this.filterdOptionsType = this.optionsType;
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
    BDropdownCustom
  },
};
</script>

<style lang="scss">
.product-stockslip {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .example {
    border: 1px solid #ddd;
    margin-bottom: 2em;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }

  .productCode:hover {
    text-decoration: underline;
  }
  .test {
    position: absolute;
    right: 1vw;
  }
  .checkbox-label {
    display: block;
  }
  .text-imei-no {
    font-size: 12px;
    font-style: italic;
    color: dodgerblue;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
